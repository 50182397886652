
.app-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
}
.heading {
  margin: 32px 0 16px;
}
.form-actions {
  margin: 24px auto;
  display: flex;
  gap: 16px;
  justify-content: right;
}
.toolbar-actions {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 16px;
}

ion-note {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.flex-line .ion-text-end {
  margin-left: auto;
}

.header-noshadow #top-header {
  box-shadow: none;
}

.hide-intercom .intercom-lightweight-app {
  display: none;
}
.intercom-lightweight-app .intercom-lightweight-app-launcher, .intercom-namespace .intercom-app .intercom-dfosxs {
  right: calc(10px + var(--ion-safe-area-right, 0px));
  max-width: 56px;
  max-height: 56px;
  width: 56px;
  height: 56px;

  .intercom-lightweight-app-launcher-icon {
    width: 56px;
    height: 56px;
  }
}

ion-button ion-label {
  line-height: 1.25;
}

ion-header {
  --ion-border-color: transparent;

  &.header-md {
    box-shadow: none;
  }
}

h1 {
  margin: 0;
}
.screen {
  --background: #F6F6F6;

  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
}

.app-form ion-item {
  margin: var(--form-item-margin, 16px 0);
  border: 1px solid var(--ion-color-light-shade);
  border-bottom: none;
  border-radius: 8px;
  --item-margin: 16px 0;

  ion-label {
    opacity: .8;
  }
  &::part(native) {
    height: 100%;
  }
}
.app-form.inline {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ion-item {
    margin: 0;
  }
}

ion-item.item-label-stacked ion-datetime-button::part(native) {
  background: none;
  padding-left: 0;
}

.pick-segment {
  --background: none;
  
  ion-segment-button {
    --indicator-color: var(--ion-color-primary);
    --color-checked: var(--ion-color-primary-contrast);
    --border-radius: 32px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 32px;
    --padding-end: 32px;
    font-size: inherit;
  }
}




@media screen and (max-width: 767.9px) {
  .intercom-lightweight-app .intercom-lightweight-app-launcher, .intercom-namespace .intercom-app .intercom-dfosxs {
    bottom: 64px;
  }
  .heading {
    margin: 16px 0;
  }
  .push-intercom {
    .intercom-lightweight-app .intercom-lightweight-app-launcher, .intercom-namespace .intercom-app:nth-child(2) {
      bottom: 148px;
    }
  }
  .ios {
    .intercom-messenger-frame {
      padding-top: 48px;
      background: black;
    }
    .intercom-lightweight-app .intercom-lightweight-app-launcher, .intercom-namespace .intercom-app:nth-child(2) {
      bottom: 90px;
    }
    .push-intercom {
      .intercom-lightweight-app .intercom-lightweight-app-launcher, .intercom-namespace .intercom-app:nth-child(2) {
        bottom: 162px;
      }
    }
  }
}
