:root {
  --ion-font-family: 'Poppins';
  --ion-default-font: 'Poppins';
  --ion-title-font: 'Poppins';

	--ion-color-primary: #009FE3;
	--ion-color-primary-rgb: 0,159,227;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #008cc8;
	--ion-color-primary-tint: #1aa9e6;

	--ion-color-secondary: #004738;
	--ion-color-secondary-rgb: 0,71,56;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #003e31;
	--ion-color-secondary-tint: #1a594c;

	--ion-color-tertiary: #1700c0;
	--ion-color-tertiary-rgb: 23,0,192;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #1400a9;
	--ion-color-tertiary-tint: #2e1ac6;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #111;
	--ion-color-success-contrast-rgb: 17,17,17;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #111;
	--ion-color-warning-contrast-rgb: 17,17,17;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #eee;
	--ion-color-danger-contrast-rgb: 238,238,238;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #111;
	--ion-color-medium-contrast-rgb: 17,17,17;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #111;
	--ion-color-light-contrast-rgb: 17,17,17;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {

  :root, body {
    --default-background: var(--ion-background-color, #fff) !important;
  }
  body {
      --ion-backdrop-opacity: 0.2;
      --ion-backdrop-color: #333333;
  }
  :root {
    --default-background: var(--ion-item-background, var(--ion-background-color, #fff));

    .ion-color-background {
      --ion-color-base: var(--ionstack-background, #fff) !important;
      --ion-color-base-rgb: var(--ionstack-background-rgb, 255, 255, 255) !important;
      --ion-color-contrast: var(--ionstack-background-contrast, #111) !important;
      --ion-color-contrast-rgb: var(--ionstack-background-contrast-rgb, 33, 33, 33) !important;
      --ion-color-shade: var(--ionstack-background-shade, #eee) !important;
      --ion-color-tint: var(--ionstack-background-tint, #fff) !important;
    }
    .glass {
      background: rgba(255, 255, 255, .3);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .2);
      border: 1px solid rgba(255, 255, 255, .2);
    }
    .glass.opaque {
      background: rgba(255, 255, 255, .6);
      border: 2px solid rgba(255, 255, 255, .3);
    }
    .dark-lighten, .dark-darken, .dark-toggle {
      filter: none;
    }
    ionstack-conversations {
      --chat-background: #fff !important;
    }
  }
}
