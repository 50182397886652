@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
  
:root, body {
    --ion-color-google: #4285F4;
    --ion-color-google-rgb: 66,133,244;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255,255,255;
    --ion-color-google-shade: #3a75d7;
    --ion-color-google-tint: #5591f5;

    --ion-color-facebook: #4267B2;
    --ion-color-facebook-rgb: 66,103,178;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255,255,255;
    --ion-color-facebook-shade: #3a5b9d;
    --ion-color-facebook-tint: #5576ba;

    --default-background: var(--ion-background-color, #fff);
    --ion-item-background: var(--default-background);
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}
  
.ion-color-google {
    --ion-color-base: var(--ion-color-google);
    --ion-color-base-rgb: var(--ion-color-google-rgb);
    --ion-color-contrast: var(--ion-color-google-contrast);
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
    --ion-color-shade: var(--ion-color-google-shade);
    --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-background {
    --ion-color-base: var(--ionstack-background, #fff) !important;
    --ion-color-base-rgb: var(--ionstack-background-rgb, 255, 255, 255) !important;
    --ion-color-contrast: var(--ionstack-background-contrast, #000) !important;
    --ion-color-contrast-rgb: var(--ionstack-background-contrast-rgb, 0, 0, 0) !important;
    --ion-color-shade: var(--ionstack-background-shade, #dedede) !important;
    --ion-color-tint: var(--ionstack-background-tint, #fff) !important;
}

a {
    color: var(--link-color, var(--ion-color-primary, #3880ff));
}

ion-skeleton-text {
  margin: 0;
  height: 1.2em;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.back-button {
    margin: 0;
}
.back-button.md {
    --border-radius: 50%;
    min-width: 48px;
    height: 48px;
}
.back-button.md ion-icon {
    font-size: 24px;
}
.back-button.ios ion-icon {
    font-size: 1.85em;
}

.dropzone.fileover {
    outline: 4px dashed rgba(var(--ion-color-primary-rgb), .6);
    background-color: rgba(var(--ion-color-primary-rgb), .3);
    --background: rgba(var(--ion-color-primary-rgb), .3);
}

.modal-gallery {
    --width: 90%;
    --height: 90%;
    --box-shadow: none;
    --background: none;
}
.large-modal {
    --width: min(95%, 800px);
    --height: min(95%, 600px);
    --border-radius: 12px;
}
.small-modal {
    --max-width: 80%;
    --max-height: 60%;
    --border-radius: 12px;
}
.auto-modal {
    --height: auto;
    --max-height: 90%;
    --overflow: auto;
}
@media screen and (orientation: portrait) {
    .small-modal {
        --max-width: 60%;
        --max-height: 80%;
    }
}

.overflow-line {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.overflow-line.over1 {
    -webkit-line-clamp: 1;
    max-height: 1.5em;
    white-space: nowrap;
}
.overflow-line.over2 {
    -webkit-line-clamp: 2;
    max-height: 3em;
}
.overflow-line.over3 {
    -webkit-line-clamp: 2;
    max-height: 4.5em;
}

.ql-editor {
    min-height: var(--editor-min-height);
    background: var(--text-background);
    font-size: var(--richtext-font-size, initial);
    line-height: var(--richtext-line-height, 1.5em);
}
.ngx-quill-view .ql-editor {
    padding: var(--padding, 0);
}
.ngx-quill-view .ql-editor > * {
    cursor: inherit;
}
.sticky-editor .ql-toolbar {
    position: sticky;
    z-index: 5;
    top: 0;
    background: var(--background, var(--default-background));
}
.sticky-editor .ql-container {
    position: relative;
}

.flex-line {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
}
.flex-action-line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.flex-action-line > *:first-child {
    flex: 1;
    margin-right: 12px;
}

.hide-plain-gallery ks-plain-gallery {
    display: none;
}

#modal-gallery-container {
  min-height: calc(100vh - 56px) !important;
}

.item-input ion-label {
    pointer-events: visible !important;
}

.vmiddle {
    vertical-align: middle;
}

.render-error {
    text-align: center;
    font-size: 24px;
    padding: 32px;
}

.render-error ion-img {
    height: 50vh;
    margin: 32px;
}

ion-item.ion-activatable {
    --padding-start: 0;
    --inner-padding-start: 16px;
}

ion-item.padding-start {
    --padding-start: 16px;
    --inner-padding-start: 0;
}

ion-button {
    max-width: 100%;
    white-space: pre-wrap;
    text-transform: none;
}

ion-button[fill="clear"] {
    text-transform: uppercase;
}

ion-button.link {
    text-transform: none;
    text-decoration: underline;
}

.input-padding .input-wrapper .native-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
}

.otp-input {
    letter-spacing: .5em;
}

.max100 {
    max-width: 100%;
}

.break-lines {
    white-space: pre-wrap;
}

.bold {
    font-weight: bold;
}

.dragover {
    outline: 2px dotted var(--ion-color-primary);
}

.clickable {
    cursor: pointer;
}

.primary-color {
    color: var(--ion-color-primary) !important;
}

.secondary-color {
    color: var(--ion-color-secondary) !important;
}

.danger-color {
    color: var(--ion-color-danger) !important;
}

.success-color {
    color: var(--ion-color-success) !important;
}

ion-popover {
    --width: min(90%, 300px);
}
ion-popover.smallable {
    --width: auto;
}
ion-popover.transparent {
    --background: none;
    --box-shadow: none;
}
ion-popover.light-backdrop {
    --ion-backdrop-color: var(--ion-color-light);
    --backdrop-opacity: 0.4;
}
ion-popover.large {
    --width: min(90%, 420px);
}

ion-popover .popover-viewport {
    height: 100%;
}

.autosize-popover .popover-viewport {
    width: 420px;
    height: 420px;
}

.md.sc-ion-select-popover ion-radio {
    margin: 8px 0 8px 16px;
}
.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    margin-bottom: 0;
}
ion-select::part(text) {
    margin-right: 4px;
}
ion-item.item-label-floating.md ion-select::part(icon), ion-item.item-label-stacked.md ion-select::part(icon) {
    top: 8px;
}

ion-title.ios {
    padding: 0;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md, .alert-radio-label.sc-ion-alert-ios,
.alert-checkbox-label.sc-ion-alert-md, .alert-checkbox-label.sc-ion-alert-ios {
    white-space: normal;
}

.alert-wrapper .alert-radio-group {
    max-height: 80%;
}

.alert-wrapper .alert-checkbox-group .alert-checkbox-button {
    display: contents;
}

.alert-wrapper .alert-radio-group.sc-ion-alert-md,
.alert-wrapper .alert-checkbox-group.sc-ion-alert-md,
.alert-wrapper .alert-radio-group.sc-ion-alert-ios,
.alert-wrapper .alert-checkbox-group.sc-ion-alert-ios {
    max-height: 70vh;
}

.select-alert .alert-wrapper {
    --max-width: 90%;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    background-color: #eee;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: #bbb;
}

.exp-header ion-item[header] {
    --background: none;
}

.inline-block {
    display: inline-block;
}

.fullheight {
    height: 100%;
}

.fullwidth {
    width: 100%;
}

ion-img.cover {
    height: 100%; 
    width: 100%;
    object-fit: cover;
}

swiper[slidesperview="auto"] .swiper-slide, swiper.slides-auto .swiper-slide {
    width: auto;
    max-width: 100%;
}
swiper[slidesperview="auto"].center-slides .swiper-wrapper, swiper.slides-auto.center.center-slides .swiper-wrapper {
    justify-content: center;
}
swiper .swiper-button-disabled {
    display: none !important;
}

.mt8  { margin-top: 8px; }
.mt16 { margin-top: 16px; }
.mt24 { margin-top: 24px; }
.mt32 { margin-top: 32px; }

.mr8  { margin-right: 8px; }
.mr16 { margin-right: 16px; }
.mr24 { margin-right: 24px; }
.mr32 { margin-right: 32px; }

.nobg {
    --background: none;
    background: none;
}

.glass {
    background: rgba(255, 255, 255, .3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, .2);
}
.glass.opaque {
    background: rgba(255, 255, 255, .6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 255, 255, .3);
}

.form-error {
    color: var(--ion-color-danger);
}

.flex-auto {
    flex: auto;
}

.display-flex {
    display: flex;
}

.w50 {
    width: 50%;
}

.w30 {
    width: 30%;
}

.w70 {
    width: 70%;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(var(--ion-color-dark),0.3);
  box-shadow: inset 0 0 6px rgba(var(--ion-color-dark),0.3);
  border-radius: 6px;
  background-color: var(--ion-color-light);
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: var(--ion-color-light);
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(var(--ion-color-dark),0.3);
  box-shadow: inset 0 0 4px rgba(var(--ion-color-dark),0.3);
  background-color: var(--ion-color-medium);
}

.scrollbar::-webkit-scrollbar:horizontal {
    height: 8px;
}

.autocomplete-popover b {
    color: var(--ionstack-autocomplete-color, var(--ion-color-primary));
}

.no-background {
    background: none;
    --background: none;
}

a:not(.a) {
  text-decoration: none;
  color: var(--ion-color-contrast);
}

ion-fab-list ion-fab-button {
    overflow: visible;
    position: relative;
    contain: layout;
}
ion-fab-list ion-fab-button ion-label {
    position: absolute;
    right: 50px;
    color: var(--color);
    background: var(--background);
    line-height: 1rem;
    padding: 4px 8px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    height: 40px;
    width: max-content;
    display: flex;
    align-items: center;
}
ion-fab-list ion-fab-button::part(native) {
    contain: initial;
    overflow: visible;
}

ion-button.autoheight {
    height: auto;
}
ion-button.autoheight ion-label {
    margin: 8px;
    line-height: 1.2em;
}
ion-button.autoheight ion-icon {
    min-width: 20px;
}

.spin, .await-clicked ion-icon, .await-submitted ion-button[type="submit"] ion-icon {
    animation-name: spin;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

.await-clicked-done ion-icon, .await-submitted-done ion-button[type="submit"] ion-icon.ios {
    animation: none;
    animation-iteration-count: 0;
}

.card {
    display: block;
    background: var(--default-background);
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.flex1 {
    flex: 1 !important;
}

@keyframes spin {
    from { transform:rotateY(0deg); }
    to { transform:rotateY(360deg); }
}

@media screen and (min-width: 768px) {
    .md .modal-wrapper {
        border-radius: 4px;
    }
}

@media screen and (max-width: 767.9px) {
    .small-hide-label ion-label {
        display: none !important;
    }
    ion-button.small-hide-label ion-icon {
        margin-inline: 0;
    }
    .mobile-fab-padding {
      --padding-bottom: 64px;
    }
}

@media (prefers-color-scheme: dark) {
    :root, body {
        --default-background: var(--ion-background-color, #3a3a3a);
    }
    body {
        --ion-backdrop-opacity: 0.85;
        --ion-backdrop-color: #333333;
    }
    .glass, .glass.opaque {
        background: rgba(0, 0, 0, .4);
        border: 2px solid rgba(175, 175, 175, .3);
        box-shadow: 0 8px 32px 0 rgb(131 138 235 / 20%);
    }
    .dark-lighten {
      filter: brightness(3);
    }
    .dark-darken {
      filter: brightness(.7);
    }
    .dark-toggle {
        filter: invert(100%);
    }
    .ion-color-background {
        --ion-color-base: var(--ionstack-background, #000) !important;
        --ion-color-base-rgb: var(--ionstack-background-rgb, 0, 0, 0) !important;
        --ion-color-contrast: var(--ionstack-background-contrast, #fff) !important;
        --ion-color-contrast-rgb: var(--ionstack-background-contrast-rgb, 255, 255, 255) !important;
        --ion-color-shade: var(--ionstack-background-shade, #1a1a1a) !important;
        --ion-color-tint: var(--ionstack-background-tint, #000) !important;
    }

    :root {
        --default-background: var(--ion-item-background, var(--ion-background-color, #121212));
    }
}

@media print {
  ion-header, ion-backdrop, .tabbar, ion-footer, ion-content::part(background) {
    display: none !important;
  }
  ion-nav {
    contain: none;
    overflow: visible;
  }
  ion-split-pane {
    display: block;
  }
  .scroll-content,
  ion-modal,
  ion-modal .modal-wrapper,
  ion-modal .ion-page,
  ion-modal .ion-page > ion-content,
  .ion-page,
  .ion-page > ion-content,
  ion-split-pane,
  ion-tab,
  ion-tabs,
  ion-router-outlet,
  .app-root,
  ion-content::part(scroll),
  body {
    contain: none;
    position: initial;
    height: auto;
    overflow: visible;
  }
  .fixed-content, .scroll-content {
    margin-top: 0 !important;
  }
}